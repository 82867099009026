import { TSpecification } from '../_types'

const stocks_specification: TSpecification = {
    market: 'indices',
    dl_title: '_t_Dynamic tiers ⁠— Stock indices_t_',
    dl_data: [
        {
            from: 0.1,
            to: 5,
            leverage: 300,
        },
        {
            from: 5.01,
            to: 50,
            leverage: 200,
        },
        {
            from: 50.01,
            to: 100,
            leverage: 100,
        },
    ],
    data: [
        {
            instrument: {
                symbol: 'STOCKSAUS200',
                instrument: 'Australia 200',
            },
            contract_size: 1,
            base_currency: 'AUD',
            minimum_size: 0.1,
            minimum_spread: 3.46,
            target_spread: 3.6,
            max_effective_leverage: 100,
            margin_requirement: 1,
            swap_long: -173.645,
            swap_short: 2.189,
            trading_hours:
                'Sun 22:50-24:00 GMT\nMon-Thur 00:00-05:30, 06:10-20:00 22:50-24:00 GMT\nFri 00:00-05:30, 06:10-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSNETHERLANDS25',
                instrument: 'Netherlands 25',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 0.31,
            target_spread: 0.31,
            max_effective_leverage: 100,
            margin_requirement: 1,
            swap_long: -21.601,
            swap_short: 0.17,
            trading_hours: 'Mon-Fri 06:00-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSEUROPE50',
                instrument: 'Europe 50',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 1.78,
            target_spread: 1.78,
            max_effective_leverage: 100,
            margin_requirement: 1,
            swap_long: -122.676,
            swap_short: 1.011,
            trading_hours: 'Mon-Fri 06:00-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSFRANCE40',
                instrument: 'France 40',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 1.08,
            target_spread: 1.26,
            max_effective_leverage: 100,
            margin_requirement: 1,
            swap_long: -208.571,
            swap_short: 1.718,
            trading_hours: 'Mon-Fri 06:00-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSGERMANY40',
                instrument: 'Germany 40',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 1.4,
            target_spread: 1.44,
            max_effective_leverage: 100,
            margin_requirement: 1,
            swap_long: -292.261,
            swap_short: 12.477,
            trading_hours: 'Mon-Fri 06:00-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSJAPAN225',
                instrument: 'Japan 225',
            },
            contract_size: 1,
            base_currency: 'JPY',
            minimum_size: 10,
            minimum_spread: 3.5,
            target_spread: 5.19,
            max_effective_leverage: 100,
            margin_requirement: 1,
            swap_long: -328.71,
            swap_short: -413.441,
            trading_hours:
                'Sun 22:00-24:00 GMT\nMon-Thur 00:00-21:00, 22:00-24:00 GMT\nFri 00:00-21:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSSPAIN35',
                instrument: 'Spain 35',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 5.9,
            target_spread: 5.99,
            max_effective_leverage: 100,
            margin_requirement: 1,
            swap_long: -265.152,
            swap_short: 2.184,
            trading_hours: 'Mon-Fri 07:00-18:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSUK100',
                instrument: 'UK 100',
            },
            contract_size: 1,
            base_currency: 'GBP',
            minimum_size: 0.1,
            minimum_spread: 0.76,
            target_spread: 1.45,
            max_effective_leverage: 100,
            margin_requirement: 1,
            swap_long: -178.695,
            swap_short: 26.53,
            trading_hours:
                'Sun 22:05-24:00 GMT\nMon-Thur 00:00-20:55, 22:05-24:00 GMT\nFri 00:00-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSUS500',
                dl_icon: true,
                instrument: 'US 500',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 0.6,
            target_spread: 0.75,
            max_effective_leverage: 100,
            margin_requirement: 1,
            swap_long: -103.824,
            swap_short: 19.802,
            trading_hours:
                'Sun 22:05-24:00 GMT\nMon-Thur 00:00-20:55, 22:05-24:00 GMT\nFri 00:00-20:55 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSUSTECH100',
                dl_icon: true,
                instrument: 'US Tech 100',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 1.26,
            target_spread: 1.5,
            max_effective_leverage: 100,
            margin_requirement: 1,
            swap_long: -329.372,
            swap_short: 62.819,
            trading_hours:
                'Sun 22:05-24:00 GMT\nMon-Thur 00:00-20:55, 22:05-24:00 GMT\nFri 00:00-20:55 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSWALLSTREET30',
                dl_icon: true,
                instrument: 'Wall Street 30',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 1.5,
            target_spread: 3.53,
            max_effective_leverage: 100,
            margin_requirement: 1,
            swap_long: -781.358,
            swap_short: 183.579,
            trading_hours:
                'Sun 22:05-24:00 GMT\nMon-Thur 00:00-20:55, 22:05-24:00 GMT\nFri 00:00-20:55 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAIRLINESGROUP',
                instrument: 'American Airlines Group Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.09,
            target_spread: 0.1,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.369,
            swap_short: 0.063,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAPPLE',
                instrument: 'Apple Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.6,
            target_spread: 0.61,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -4.341,
            swap_short: 0.737,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAIRBNB',
                instrument: 'Airbnb Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.42,
            target_spread: 0.47,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -2.893,
            swap_short: 0.491,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSADIDASSALOMON',
                instrument: 'Adidas Salomon',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.92,
            target_spread: 12.66,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -3.078,
            swap_short: 0.06,
            trading_hours: 'Mon Fri 08:00 - 16:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAMERICANINTERNATIONAL',
                instrument: 'American International Group',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.24,
            target_spread: 0.27,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.347,
            swap_short: 0.229,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAIRBUSSE',
                instrument: 'Airbus SE',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.41,
            target_spread: 1.65,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -2.363,
            swap_short: 0.046,
            trading_hours: 'Mon Fri 08:00 - 16:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAIRFRANCE',
                instrument: 'Air France KLM SA',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.03,
            target_spread: 4.25,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.03,
            swap_short: 0.001,
            trading_hours: 'Mon Fri 08:00 - 16:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSADVANCEDMICRODEVICES',
                instrument: 'Advanced Micro Devices Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.48,
            target_spread: 0.49,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -2.439,
            swap_short: 0.414,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAMAZON',
                instrument: 'Amazon.com Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.62,
            target_spread: 0.63,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -2.691,
            swap_short: 0.457,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSBOEING',
                instrument: 'Boeing',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.82,
            target_spread: 0.99,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -5.577,
            swap_short: 0.947,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSALIBABAGROUP',
                instrument: 'Alibaba Group Holding Ltd',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.59,
            target_spread: 0.6,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -2.709,
            swap_short: 0.46,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSBANKOFAMERICA',
                instrument: 'Bank of America',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.19,
            target_spread: 0.21,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.735,
            swap_short: 0.125,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSBAYERAG',
                instrument: 'Bayer AG',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.18,
            target_spread: 0.83,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.134,
            swap_short: 0.022,
            trading_hours: 'Mon Fri 08:00 - 16:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSBIOGEN',
                instrument: 'Biogen Inc.',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.78,
            target_spread: 1.11,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -7.556,
            swap_short: 1.284,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSBMW',
                instrument: 'BMW',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.34,
            target_spread: 81.3,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.85,
            swap_short: 0.036,
            trading_hours: 'Mon Fri 08:00 - 16:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSCITIGROUP',
                instrument: 'Citigroup',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.26,
            target_spread: 0.26,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.21,
            swap_short: 0.206,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSCONTINENTAL',
                instrument: 'Continental AG',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.33,
            target_spread: 0.97,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.217,
            swap_short: 0.024,
            trading_hours: 'Mon Fri 08:00 - 16:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSSALESFORCE',
                instrument: 'Salesforce.com Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.7,
            target_spread: 0.76,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -5.079,
            swap_short: 0.863,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSCISCO',
                instrument: 'Cisco Systems',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.24,
            target_spread: 0.25,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.352,
            swap_short: 0.23,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSDELTAAIRLINES',
                instrument: 'Delta Air Lines',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.19,
            target_spread: 0.19,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.888,
            swap_short: 0.151,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSDEUTSCHEBANK',
                instrument: 'Deutsche Bank AG',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.06,
            target_spread: 736.49,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.177,
            swap_short: 0.004,
            trading_hours: 'Mon Fri 08:00 - 16:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSWALTDISNEY',
                instrument: 'Walt Disney Company',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.65,
            target_spread: 0.69,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -2.638,
            swap_short: 0.448,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSEBAY',
                instrument: 'eBay Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.25,
            target_spread: 0.26,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.149,
            swap_short: 0.195,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSFACEBOOK',
                instrument: 'Meta Platforms Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.31,
            target_spread: 1.34,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -5.701,
            swap_short: 0.968,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSFEDEX',
                instrument: 'Fedex',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.82,
            target_spread: 1.09,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -6.122,
            swap_short: 1.04,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSFOX',
                instrument: 'Fox Corporation B',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.15,
            target_spread: 0.16,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.811,
            swap_short: 0.138,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSGENERALMOTORSCOMP',
                instrument: 'General Motors Company',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.22,
            target_spread: 0.22,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.913,
            swap_short: 0.155,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSALPHABET',
                instrument: 'Alphabet Inc Class C',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 9.5,
            target_spread: 9.57,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -2.872,
            swap_short: 0.488,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSGOLDMANSACHS',
                instrument: 'Goldman Sachs Group',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.41,
            target_spread: 1.6,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -8.502,
            swap_short: 1.444,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSHOME',
                instrument: 'Home Depot Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.47,
            target_spread: 0.56,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -7.612,
            swap_short: 1.293,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSHPINC',
                instrument: 'HP Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.13,
            target_spread: 0.14,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.774,
            swap_short: 0.132,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSIBM',
                instrument: 'IBM',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.56,
            target_spread: 0.59,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -3.442,
            swap_short: 0.585,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSINTEL',
                instrument: 'Intel',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.23,
            target_spread: 0.24,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.866,
            swap_short: 0.147,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSJOHNSONJOHNSON',
                instrument: 'Johnson & Johnson',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.66,
            target_spread: 0.68,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -4.356,
            swap_short: 0.74,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSJPMORGANCHASE',
                instrument: 'JP Morgan Chase',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.63,
            target_spread: 0.65,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -3.362,
            swap_short: 0.571,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSCOCACOLA',
                instrument: 'Coca-Cola',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.23,
            target_spread: 0.24,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.658,
            swap_short: 0.281,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSLUFTHANSA',
                instrument: 'Lufthansa AG',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.03,
            target_spread: 284.61,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.192,
            swap_short: 0.004,
            trading_hours: 'Mon Fri 08:00 - 16:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSMASTERCARDINC',
                instrument: 'Mastercard Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.18,
            target_spread: 1.34,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -9.532,
            swap_short: 1.619,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSMCDONALDS',
                instrument: 'McDonalds',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.88,
            target_spread: 0.95,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -7.463,
            swap_short: 1.268,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSMODERNA',
                instrument: 'Moderna Inc.',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.74,
            target_spread: 0.83,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -4.172,
            swap_short: 0.708,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSMICROSOFT',
                instrument: 'Microsoft Corporation',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.11,
            target_spread: 1.13,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -7.69,
            swap_short: 1.306,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSNETFLIX',
                instrument: 'Netflix Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.75,
            target_spread: 1.95,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -8.947,
            swap_short: 1.52,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSNIKE',
                instrument: 'Nike',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.56,
            target_spread: 0.58,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -3.172,
            swap_short: 0.539,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSNVIDIA',
                instrument: 'NVIDIA Corp',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.35,
            target_spread: 1.4,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -7.129,
            swap_short: 1.211,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPEPSICO',
                instrument: 'PepsiCo Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.58,
            target_spread: 0.61,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -4.862,
            swap_short: 0.826,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPFIZER',
                instrument: 'Pfizer',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.19,
            target_spread: 0.21,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.095,
            swap_short: 0.186,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPROCTERGAMBLE',
                instrument: 'Procter & Gamble',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.57,
            target_spread: 0.6,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -4.014,
            swap_short: 0.682,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPORSCHE',
                instrument: 'Porsche Automobil Holding SE',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.28,
            target_spread: 11.27,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.979,
            swap_short: 0.019,
            trading_hours: 'Mon Fri 08:00 - 16:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPUMA',
                instrument: 'Puma SE',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.34,
            target_spread: 10.34,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.005,
            swap_short: 0.02,
            trading_hours: 'Mon Fri 08:00 - 16:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPAYPAL',
                instrument: 'Paypal',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.73,
            target_spread: 0.74,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.976,
            swap_short: 0.336,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSSONY',
                instrument: 'Sony Corporation',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.54,
            target_spread: 0.64,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -2.327,
            swap_short: 0.396,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSTEVAPHARMACEUTICAL',
                instrument: 'Teva Pharmaceutical Inds',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.04,
            target_spread: 0.24,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.244,
            swap_short: 0.041,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSTESLA',
                instrument: 'Tesla Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.12,
            target_spread: 1.14,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -4.88,
            swap_short: 0.829,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSUBER',
                instrument: 'Uber Technologies Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.14,
            target_spread: 0.18,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -0.823,
            swap_short: 0.14,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSVISA',
                instrument: 'Visa',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.76,
            target_spread: 0.84,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -5.962,
            swap_short: 1.013,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSWALMART',
                instrument: 'Wal-Mart Stores Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.55,
            target_spread: 0.6,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -3.977,
            swap_short: 0.676,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSZOOM',
                instrument: 'Zoom Video Communications Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.59,
            target_spread: 0.63,
            max_effective_leverage: 50,
            margin_requirement: 2,
            swap_long: -1.895,
            swap_short: 0.322,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
    ],
    eu_data: [
        {
            instrument: {
                symbol: 'STOCKSADIDASSALOMON',
                instrument: 'Adidas Salomon',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.92,
            target_spread: 12.66,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -3.078,
            swap_short: 0.06,
            trading_hours: 'Mon Fri 07:00 - 15:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAIRBUSSE',
                instrument: 'Airbus SE',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.41,
            target_spread: 1.65,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -2.363,
            swap_short: 0.046,
            trading_hours: 'Mon Fri 07:00 - 15:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAIRFRANCE',
                instrument: 'Air France KLM SA',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.03,
            target_spread: 4.25,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.03,
            swap_short: 0.001,
            trading_hours: 'Mon Fri 07:00 - 15:30 GMT',
        },
        {
            instrument: {
                symbol: 'BAY',
                instrument: 'Bayer AG',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.18,
            target_spread: 0.83,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.134,
            swap_short: 0.022,
            trading_hours: 'Mon Fri 07:00 - 15:30 GMT',
        },
        {
            instrument: {
                symbol: 'BMW',
                instrument: 'BMW',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.34,
            target_spread: 81.3,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.85,
            swap_short: 0.036,
            trading_hours: 'Mon Fri 07:00 - 15:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSCONTINENTAL',
                instrument: 'Continental AG',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.33,
            target_spread: 0.97,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.217,
            swap_short: 0.024,
            trading_hours: 'Mon Fri 07:00 - 15:30 GMT',
        },
        {
            instrument: {
                symbol: 'DBK',
                instrument: 'Deutsche Bank AG',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.06,
            target_spread: 736.49,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.177,
            swap_short: 0.004,
            trading_hours: 'Mon Fri 07:00 - 15:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSLUFTHANSA',
                instrument: 'Lufthansa AG',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.03,
            target_spread: 284.61,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.192,
            swap_short: 0.004,
            trading_hours: 'Mon Fri 07:00 - 15:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPORSCHE',
                instrument: 'Porsche Automobil Holding SE',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.28,
            target_spread: 11.27,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.979,
            swap_short: 0.019,
            trading_hours: 'Mon Fri 07:00 - 15:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPUMA',
                instrument: 'Puma SE',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 1,
            minimum_spread: 0.34,
            target_spread: 10.34,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.005,
            swap_short: 0.02,
            trading_hours: 'Mon Fri 07:00 - 15:30 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAUS200',
                instrument: 'Australia 200',
            },
            contract_size: 1,
            base_currency: 'AUD',
            minimum_size: 0.1,
            minimum_spread: 3.46,
            target_spread: 3.6,
            max_effective_leverage: 20,
            margin_requirement: 5,
            swap_long: -173.645,
            swap_short: 2.189,
            trading_hours:
                'Sun 22:50-24:00 GMT\nMon-Thur 00:00-05:30, 06:10-20:00 22:50-24:00 GMT\nFri 00:00-05:30, 06:10-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSEUROPE50',
                instrument: 'Europe 50',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 1.78,
            target_spread: 1.78,
            max_effective_leverage: 20,
            margin_requirement: 5,
            swap_long: -122.676,
            swap_short: 1.011,
            trading_hours: 'Mon-Fri 06:00-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSFRANCE40',
                instrument: 'France 40',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 1.08,
            target_spread: 1.26,
            max_effective_leverage: 20,
            margin_requirement: 5,
            swap_long: -208.571,
            swap_short: 1.718,
            trading_hours: 'Mon-Fri 06:00-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSGERMANY40',
                instrument: 'Germany 40',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 1.4,
            target_spread: 1.44,
            max_effective_leverage: 20,
            margin_requirement: 5,
            swap_long: -292.261,
            swap_short: 12.477,
            trading_hours: 'Mon-Fri 06:00-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSJAPAN225',
                instrument: 'Japan 225',
            },
            contract_size: 1,
            base_currency: 'JPY',
            minimum_size: 10,
            minimum_spread: 3.5,
            target_spread: 5.19,
            max_effective_leverage: 20,
            margin_requirement: 5,
            swap_long: -328.71,
            swap_short: -413.441,
            trading_hours:
                'Sun 22:00-24:00 GMT\nMon-Thur 00:00-21:00, 22:00-24:00 GMT\nFri 00:00-21:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSSPAIN35',
                instrument: 'Spain 35',
            },
            contract_size: 1,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 5.9,
            target_spread: 5.99,
            max_effective_leverage: 10,
            margin_requirement: 10,
            swap_long: -265.152,
            swap_short: 2.184,
            trading_hours: 'Mon-Fri 07:00-18:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSUK100',
                instrument: 'UK 100',
            },
            contract_size: 1,
            base_currency: 'GBP',
            minimum_size: 0.1,
            minimum_spread: 0.76,
            target_spread: 1.45,
            max_effective_leverage: 20,
            margin_requirement: 5,
            swap_long: -178.695,
            swap_short: 26.53,
            trading_hours:
                'Sun 22:05-24:00 GMT\nMon-Thur 00:00-20:55, 22:05-24:00 GMT\nFri 00:00-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSUS500',
                instrument: 'US 500',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 0.6,
            target_spread: 0.75,
            max_effective_leverage: 20,
            margin_requirement: 5,
            swap_long: -103.824,
            swap_short: 19.802,
            trading_hours:
                'Sun 22:05-24:00 GMT\nMon-Thur 00:00-20:55, 22:05-24:00 GMT\nFri 00:00-20:55 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSUSTECH100',
                instrument: 'US Tech 100',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 1.26,
            target_spread: 1.5,
            max_effective_leverage: 20,
            margin_requirement: 5,
            swap_long: -329.372,
            swap_short: 62.819,
            trading_hours:
                'Sun 22:05-24:00 GMT\nMon-Thur 00:00-20:55, 22:05-24:00 GMT\nFri 00:00-20:55 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSWALLSTREET30',
                instrument: 'Wall Street 30',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 1.5,
            target_spread: 3.53,
            max_effective_leverage: 20,
            margin_requirement: 5,
            swap_long: -781.358,
            swap_short: 183.579,
            trading_hours:
                'Sun 22:05-24:00 GMT\nMon-Thur 00:00-20:55, 22:05-24:00 GMT\nFri 00:00-20:55 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAIRLINESGROUP',
                instrument: 'American Airlines Group Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.09,
            target_spread: 0.1,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.369,
            swap_short: 0.063,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAPPLE',
                instrument: 'Apple Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.6,
            target_spread: 0.61,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -4.341,
            swap_short: 0.737,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAIRBNB',
                instrument: 'Airbnb Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.42,
            target_spread: 0.47,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -2.893,
            swap_short: 0.491,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAMERICANINTERNATIONAL',
                instrument: 'American International Group',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.24,
            target_spread: 0.27,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.347,
            swap_short: 0.229,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSADVANCEDMICRODEVICES',
                instrument: 'Advanced Micro Devices Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.48,
            target_spread: 0.49,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -2.439,
            swap_short: 0.414,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSAMAZON',
                instrument: 'Amazon.com Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.62,
            target_spread: 0.63,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -2.691,
            swap_short: 0.457,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSBOEING',
                instrument: 'Boeing',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.82,
            target_spread: 0.99,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -5.577,
            swap_short: 0.947,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSALIBABAGROUP',
                instrument: 'Alibaba Group Holding Ltd',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.59,
            target_spread: 0.6,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -2.709,
            swap_short: 0.46,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSBANKOFAMERICA',
                instrument: 'Bank of America',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.19,
            target_spread: 0.21,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.735,
            swap_short: 0.125,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSBIOGEN',
                instrument: 'Biogen Inc.',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.78,
            target_spread: 1.11,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -7.556,
            swap_short: 1.284,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSCITIGROUP',
                instrument: 'Citigroup',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.26,
            target_spread: 0.26,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.21,
            swap_short: 0.206,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSSALESFORCE',
                instrument: 'Salesforce.com Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.7,
            target_spread: 0.76,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -5.079,
            swap_short: 0.863,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSCISCO',
                instrument: 'Cisco Systems',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.24,
            target_spread: 0.25,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.352,
            swap_short: 0.23,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSDELTAAIRLINES',
                instrument: 'Delta Air Lines',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.19,
            target_spread: 0.19,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.888,
            swap_short: 0.151,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSWALTDISNEY',
                instrument: 'Walt Disney Company',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.65,
            target_spread: 0.69,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -2.638,
            swap_short: 0.448,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSEBAY',
                instrument: 'eBay Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.25,
            target_spread: 0.26,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.149,
            swap_short: 0.195,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSFACEBOOK',
                instrument: 'Meta Platforms Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.31,
            target_spread: 1.34,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -5.701,
            swap_short: 0.968,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSFEDEX',
                instrument: 'Fedex',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.82,
            target_spread: 1.09,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -6.122,
            swap_short: 1.04,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSFOX',
                instrument: 'Fox Corporation B',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.15,
            target_spread: 0.16,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.811,
            swap_short: 0.138,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSGENERALMOTORSCOMP',
                instrument: 'General Motors Company',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.22,
            target_spread: 0.22,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.913,
            swap_short: 0.155,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSALPHABET',
                instrument: 'Alphabet Inc Class C',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.5,
            target_spread: 0.57,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -2.872,
            swap_short: 0.488,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSGOLDMANSACHS',
                instrument: 'Goldman Sachs Group',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.41,
            target_spread: 1.6,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -8.502,
            swap_short: 1.444,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSHOME',
                instrument: 'Home Depot Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.47,
            target_spread: 0.56,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -7.612,
            swap_short: 1.293,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSHPINC',
                instrument: 'HP Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.13,
            target_spread: 0.14,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.774,
            swap_short: 0.132,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSIBM',
                instrument: 'IBM',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.56,
            target_spread: 0.59,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -3.442,
            swap_short: 0.585,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSINTEL',
                instrument: 'Intel',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.23,
            target_spread: 0.24,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.866,
            swap_short: 0.147,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSJOHNSONJOHNSON',
                instrument: 'Jonhson & Johnson',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.66,
            target_spread: 0.68,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -4.356,
            swap_short: 0.74,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSJPMORGANCHASE',
                instrument: 'JP Morgan Chase',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.63,
            target_spread: 0.65,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -3.362,
            swap_short: 0.571,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSCOCACOLA',
                instrument: 'Coca-Cola',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.23,
            target_spread: 0.24,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.658,
            swap_short: 0.281,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSMASTERCARDINC',
                instrument: 'Mastercard Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.18,
            target_spread: 1.34,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -9.532,
            swap_short: 1.619,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSMCDONALDS',
                instrument: 'McDonalds',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.88,
            target_spread: 0.95,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -7.463,
            swap_short: 1.268,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSMODERNA',
                instrument: 'Moderna Inc.',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.74,
            target_spread: 0.83,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -4.172,
            swap_short: 0.708,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSMICROSOFT',
                instrument: 'Microsoft Corporation',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.11,
            target_spread: 1.13,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -7.69,
            swap_short: 1.306,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSNETFLIX',
                instrument: 'Netflix Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.75,
            target_spread: 1.95,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -8.947,
            swap_short: 1.52,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSNIKE',
                instrument: 'Nike',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.56,
            target_spread: 0.58,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -3.172,
            swap_short: 0.539,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSNVIDIA',
                instrument: 'NVIDIA Corp',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.35,
            target_spread: 1.4,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -7.129,
            swap_short: 1.211,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPEPSICO',
                instrument: 'PepsiCo Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.58,
            target_spread: 0.61,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -4.862,
            swap_short: 0.826,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPFIZER',
                instrument: 'Pfizer',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.19,
            target_spread: 0.21,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.095,
            swap_short: 0.186,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPROCTERGAMBLE',
                instrument: 'Procter & Gamble',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.57,
            target_spread: 0.6,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -4.014,
            swap_short: 0.682,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSPAYPAL',
                instrument: 'Paypal',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.73,
            target_spread: 0.74,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.976,
            swap_short: 0.336,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSSONY',
                instrument: 'Sony Corporation',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.54,
            target_spread: 0.64,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -2.327,
            swap_short: 0.396,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSTEVAPHARMACEUTICAL',
                instrument: 'Teva Pharmaceutical Inds',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.04,
            target_spread: 0.24,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.244,
            swap_short: 0.041,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSTESLA',
                instrument: 'Tesla Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 1.12,
            target_spread: 1.14,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -4.88,
            swap_short: 0.829,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSUBER',
                instrument: 'Uber Technologies Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.14,
            target_spread: 0.18,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -0.823,
            swap_short: 0.14,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSVISA',
                instrument: 'Visa',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.76,
            target_spread: 0.84,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -5.962,
            swap_short: 1.013,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSWALMART',
                instrument: 'Wal-Mart Stores Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.55,
            target_spread: 0.6,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -3.977,
            swap_short: 0.676,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
        {
            instrument: {
                symbol: 'STOCKSZOOM',
                instrument: 'Zoom Video Communications Inc',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.59,
            target_spread: 0.63,
            max_effective_leverage: 150,
            margin_requirement: 0.67,
            swap_long: -1.895,
            swap_short: 0.322,
            trading_hours: 'Mon-Fri 13:30-20:00 GMT',
        },
    ],
}
export default stocks_specification
